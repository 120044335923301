import { useCurrentUserStore } from '~/stores/currentUser'
import { SourceAccessType, type Source } from '~/types/sources'

export const getSourceDisplayName = (source: Source): string => {
  const { $t } = useNuxtApp()

  const displayName = $t(
    `settings.integrations.dataSources.sources.choices.${source.dataSource}.title`
  )

  return source.accountName
    ? displayName + ` - ${source.accountName}`
    : displayName
}

export const hasEditorPermissionBySource = (sourceId: string) => {
  const { sourcePermissions, isCurrentUserAdmin } = useCurrentUserStore()

  return (
    sourcePermissions.some(
      permission =>
        permission.sourceAccessType === SourceAccessType.EDITOR &&
        permission.source.id === sourceId
    ) || isCurrentUserAdmin
  )
}
